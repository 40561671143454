import Axios from "./index";
import { parseFilters } from "./helper";

export const showCurrentUser = () => {
  return Axios.get(`/user/users/me`);
};

export const updateCurrentUser = params => {
  return Axios.put(`/user/users/me`, params);
};

export const updateCurrentUserBio = params => {
  return Axios.put(`/user/users/update_podacaster_bio`, params);
};

export const showUser = id => {
  return Axios.get(`/user/users/${id}`);
};

export const indexUsers = params => {
  return Axios.get(`/user/users`, { params: parseFilters(params) });
};

export const indexTop10Artists = (genre) => {
  return Axios.get(`/user/users/top_10_artists`, { params: { genre } });
};

export const followUser = user_id => {
  return Axios.post(`/user/users/followships`, { user_id });
};

export const unfollowUser = params => {
  return Axios.delete(`/user/users/followships`, { params });
};
export const indexUserFollowers = params => {
  return Axios.get(`/user/users/followships/followers`, { params });
};
export const indexUserFollowings = params => {
  return Axios.get(`/user/users/followships/followings`, { params });
};

export const updateUserPassword = params => {
  return Axios.put(`/user/users/me/update_password`, params);
};

export const whoToFollow = () => {
  return Axios.get(`/user/users/who_to_follow`);
};

export const myDashboard = params => {
  return Axios.get(`/user/users/my_dashboard`, { params });
};

export const subscribeToPrimo = params => {
  return Axios.put(`/user/users/me/subscribe_to_primo`, params);
};

export const unsubscribeFromPrimo = () => {
  return Axios.put(`/user/users/me/unsubscribe_from_primo`);
};

export const subscribeToTipjar = params => {
  return Axios.put(`/user/users/me/subscribe_to_tipjar`, params);
};

export const unsubscribeFromTipjar = () => {
  return Axios.put(`/user/users/me/unsubscribe_from_tipjar`);
};

export const deleteMyAccount = user_id => {
  return Axios.delete(`/user/users/${user_id}`);
};
