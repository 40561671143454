import { SET_VOLUME, TOGGLE_MUTE } from "../actions";

export default (state = { percentile: 75, muted: false }, action) => {
  switch (action.type) {
    case SET_VOLUME:
      return { muted: false, percentile: action.percentile };
    case TOGGLE_MUTE:
      return { ...state, muted: !state.muted };
    default:
      return state;
  }
};
