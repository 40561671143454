import "./index.scss";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

import AppLoader from "./AppLoader";
import ConfirmEmail from "./ConfirmEmail";
import Blog from "./components/Blog";
import { assets } from "./public/assets";

const PATHS = assets.images.onboarding.landing;
const ogImages = Object.keys(PATHS).map((bg) => PATHS[bg].default);
const randomImage = Math.floor(Math.random() * ogImages.length);

ReactDOM.render(
  <>
    <Helmet>
      <meta name="og:image" content={ogImages[randomImage]} />
    </Helmet>
    <Router>
      <Switch>
        <Redirect from={`/home`} to={`/`} />
        <Route exact path="/confirm_email/:confirmation_token" component={ConfirmEmail} />
        <Route path="/blog" component={Blog} />
        <Route path="/" component={AppLoader} />
      </Switch>
    </Router>
  </>,
  document.getElementById("root")
);
