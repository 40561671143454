import Axios from "./index";
import { parseFilters } from "./helper";

export const indexMedia = (params) => {
  return Axios.get(`/user/media`, { params: parseFilters(params) });
};

export const createMedium = (MediaParams) => {
  return Axios.post(`/user/media`, MediaParams);
};

export const createLink = (MediaParams) => {
  return Axios.post(`/user/link`, MediaParams);
};

export const createMedia = (MediaParams) => {
  return Axios.post(`/user/media/bulk`, MediaParams);
};

export const showMedium = (medium_id) => {
  return Axios.get(`/user/media/${medium_id}`);
};

export const deleteMedium = (medium_id) => {
  return Axios.delete(`/user/media/${medium_id}`);
};

export const playMedium = (medium_id) => {
  return Axios.put(`/user/media/${medium_id}/play`);
};

export const draggableMediaTypes = ["audio/mp3", "audio/x-m4a", "audio/mpeg", "audio/x-aiff", "audio/wav", "audio/ogg","audio/flac"];
export const fileMediumTypesMap = {
  "audio/x-m4a": "SoundTrack",
  "audio/mpeg": "SoundTrack",
  "audio/mp3": "SoundTrack",
  "audio/wav": "SoundTrack",
  "audio/ogg": "SoundTrack",
  "audio/flac": "SoundTrack",
  "audio/x-aiff": "SoundTrack"
};
