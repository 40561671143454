import { SET_PLAYER_REF } from "../actions";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PLAYER_REF:
      return action.player;
    default:
      return state;
  }
};
