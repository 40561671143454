import { SET_NEXT_UP, CONCAT_NEXT_UP } from "../actions";

export default (state = true, action) => {
  switch (action.type) {
    case SET_NEXT_UP:
      return action.next_up ? action.next_up.length === 10 : true;
    case CONCAT_NEXT_UP:
      return action.next_up ? action.next_up.length === 10 : true;
    default:
      return state;
  }
};
