import { PAUSE, PLAY } from "../actions";

export default (state = {}, action) => {
    switch (action.type) {
        case PAUSE:
            return {
                ...state,
                [action.videoRef.url]: {
                    vRef :  action.videoRef.videoRef,
                    paused: false
                }
            }
        case PLAY:
            const itemSelectedValue = Object.assign({},state);
            Object.keys(itemSelectedValue).forEach((item, index) => {
                itemSelectedValue[item].paused = false
                if(action.videoRef.url == item){
                    itemSelectedValue[item].paused = true
                }
            })

            return itemSelectedValue;
        default:
            return state;

    }
};
