import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default ({ post, isMain }) => {
  const thumbnail = post["_embedded"] && post["_embedded"]["wp:featuredmedia"] && post["_embedded"]["wp:featuredmedia"][0].source_url;
  const categories = post["_embedded"] && post["_embedded"]["wp:term"] && post["_embedded"]["wp:term"][0];
  return (
    <article className="post type-post status-publish format-standard has-post-thumbnail hentry category-live-stream entry">
      <header className="entry-header alignwide">
        {isMain ? (
          <h2 className="entry-title default-max-width">
            <Link to={"/blog/" + post.slug}>
              <span className="align-text-top">{post.title.rendered}</span>
            </Link>
          </h2>
        ) : (
          <h1 className="entry-title">{post.title.rendered}</h1>
        )}
        {thumbnail && (
          <figure className="post-thumbnail">
            <img
              width="560"
              height="372"
              src={post["_embedded"]["wp:featuredmedia"][0].source_url}
              className="attachment-post-thumbnail size-post-thumbnail wp-post-image"
              alt=""
            />
          </figure>
        )}
      </header>
      {isMain ? (
        <div
          className="entry-content"
          dangerouslySetInnerHTML={{ __html: post.excerpt.rendered.replace('href="https://blog.unifimusic.ai/', 'href="/blog/') }}
        ></div>
      ) : (
        <div className="entry-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
      )}
      <footer className="entry-footer default-max-width">
        <span className="posted-on">
          Published <time className="entry-date published updated">{moment(post.date).format("MMM DD, YYYY")}</time>
        </span>
        {categories.length > 0 && (
          <div className="post-taxonomies">
            <span className="cat-links">
              Categorized as&nbsp;
              {categories.map((category, i) => (
                <span key={i}>
                  <Link to={"/blog/category/" + category.slug}>{category.name}</Link>
                  {i < categories.length - 1 && <span>, </span>}
                </span>
              ))}
            </span>
          </div>
        )}
      </footer>
    </article>
  );
};
