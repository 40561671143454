import React from "react";
import { Spinner } from "react-bootstrap";
export default (props) => {
  const { status, meta, className = null } = props;
  return (
    <div
      className={`full-page-spinner d-flex flex-column justify-content-center align-items-center ${className}`}
      style={{ height: "100%", width: "100%" }}
    >
      <Spinner
        animation="border"
        variant="primary"
        size="lg"
        role="status"
        style={{ width: "5rem", height: "5rem" }}
      >
        <span className="sr-only">Loading...</span>
      </Spinner>
      <p className="mt-3 text-primary mb-0">{status}</p>
      <small className="text-light mb-0">{meta !== status ? meta : ""}</small>
    </div>
  );
};
