import React from "react";
import { BrowserRouter as Router, Route, Switch, useRouteMatch } from "react-router-dom";
import "./styles.scss";
import Article from "./article";
import Articles from "./articles";
import Category from "./category";

export default function Blog() {
  const { path } = useRouteMatch();

  return (
    <div id="blog" className="site">
      <header id="masthead" className="site-header has-logo" role="banner">
        <div className="site-branding">
          <div className="site-logo">
            <a href="/blog" className="custom-logo-link" rel="home">
              <img
                src="https://blog.unifimusic.ai/wp-content/uploads/2024/08/Black.gif"
                className="custom-logo"
                alt="UNIFI Music - For the Fiercely Independent Artist."
              />
            </a>
          </div>
          <p className="screen-reader-text">
            <a href="/blog">UNIFI Music</a>
          </p>
        </div>
      </header>
      <div id="content" className="site-content">
        <div id="primary" className="content-area">
          <Router>
            <Switch>
              <Route path={`${path}/category/:slug`} component={Category} />
              <Route path={`${path}/:slug`} component={Article} />
              <Route exact path={`${path}`} component={Articles} />
            </Switch>
          </Router>
        </div>
      </div>
      <footer id="colophon" className="site-footer" role="contentinfo">
        <div className="site-info">
          <div className="site-name">
            <div className="site-logo">
              <a href="/blog" className="custom-logo-link" rel="home">
                <img
                  src="https://blog.unifimusic.ai/wp-content/uploads/2024/08/Black.gif"
                  className="custom-logo"
                  alt="UNIFI Music - For the Fiercely Independent Artist."
                />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
