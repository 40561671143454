import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import FullPageSpinner from "../FullPageSpinner";
import Post from "./post";
import { getPost } from "../../api/blogs";

export default () => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [post, setPost] = useState({});

  useEffect(() => {
    console.log(slug);
    getPost(slug).then(({ data }) => {
      setPost(data[0]);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <FullPageSpinner />
  ) : (
    <main id="main" className="site-main singular single" role="main">
      <Helmet>
        <title>{`${post.title.rendered} - UNIFI Music`}</title>
        <meta name="description" content={post.meta_description} />
        <meta name="og:title" content={`${post.title.rendered} - UNIFI Music`} />
        <meta name="og:description" content={post.meta_description} />
        <meta
          name="og:image"
          content={post["_embedded"] && post["_embedded"]["wp:featuredmedia"] && post["_embedded"]["wp:featuredmedia"][0].source_url}
        />
      </Helmet>
      <Post post={post} />
    </main>
  );
};
