import { SET_FILE } from "../actions";

export default (state = null, action) => {
  switch (action.type) {
    case SET_FILE:
      return action.file;
    default:
      return state;
  }
};
