import uuid from "uuid/v1";
import store from "./../redux/store";
import { showCurrentUser } from "./users";
import Actions from "../redux/actions";
import moment from "moment-shortformat";
import { createMedium } from "./media";

export const isSelfId = (id) => !!store.getState().current_user && store.getState().current_user.id === id;
export const isSelfUsername = (username) => !!store.getState().current_user && store.getState().current_user.username === username;
export const isInQueue = (medium) => store.getState()?.queue?.queue?.includes(medium.id);
export const getCurrentUser = () => store.getState().current_user;
export const updateCurrentUserRedux = () => {
  store.dispatch(Actions.setCurrentUser({}));
  showCurrentUser().then(({ data: { user, meta } }) => store.dispatch(Actions.setCurrentUser(user, meta)));
};
export const getUpdatedCurrentUser = () =>
  new Promise((resolve, reject) => {
    showCurrentUser().then(({ data: { user, meta } }) => {
      store.dispatch(Actions.setCurrentUser(user, meta));
      resolve(user);
    });
  });

export const makesItemAtTopOfArray = (arr, item) => {
  return arr.reduce((acc, element) => {
    if (element === item) {
      return [element, ...acc];
    }
    return [...acc, element];
  }, []);
};

export const parseFilters = (params) => {
  if (!params || !params.filter) return params;
  let filter = params.filter;
  let newFilter = {};
  Object.keys(filter).forEach((k) => {
    if (filter[k] !== null || filter[k] !== undefined) newFilter[`filter[${k}]`] = filter[k];
  });
  delete params.filter;
  return { ...params, ...newFilter };
};

export const filterTimeStamp = (timeStamp, short = true) => {
  let time = moment(timeStamp);
  if (short) {
    time = time.short(true);
  } else {
    time = time.format("h:mma on dddd, MMMM D");
  }
  if (time.length <= 3) {
    time = time.split("");
    if (time[time.length - 1].toLowerCase() === "s") {
      time = time.join("").replace(time.join(""), "Just Now");
    }
  }
  return time;
};

export const object_url = (filename) => uuid();

export const watch = (ob_a, ob_b, k) => ob_a[k] !== ob_b[k];

export const mapMediumtype = (_t) => {
  if (_t.startsWith("video/")) {
    return "Video";
  } else if (_t.startsWith("image/")) {
    return "Image";
  } else if (_t.startsWith("audio/")) {
    return "SoundTrack";
  } else {
    return "";
  }
};

export const truncateSentence = (sentence, amount, tail = "...") => {
  if (!sentence) return;
  if (amount >= sentence.length) {
    return sentence;
  }
  const truncated = sentence.slice(0, amount);
  return `${truncated}${tail}`;
};

export const abstractString = (str) => {
  return str.replace(/[^A-Z0-9]+/gi, "").toLowerCase();
};

export const uploadMedia = (s3_object_key) => {
  return createMedium({
    media: {
      type: "ProfilePicture",
      s3_object_key,
    },
  });
};
