import { SET_CURRENT_USER } from "../actions";

export default (state = null, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      return action.current_user !== null
        ? {
            ...action.current_user,
            unread_notifications_count: action.unread_notifications_count
          }
        : null;
    default:
      return state;
  }
};
