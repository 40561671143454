import Axios from "./index";

export const signup = signupParams => {
  return Axios.post("/guest/guests", signupParams);
};

export const update = guestParamas => {
  return Axios.put("/guest/guests", guestParamas);
};

export const showCurrentGuest = () => {
  return Axios.get("/guest/guests");
};

export const completeRegistration = () => {
  return Axios.post("/guest/guests/complete_registration");
};

export const confirmEmail = token => {
  return Axios.post("/guest/guests/confirm_email_address", token);
};

export const requestResetPassword = resetPasswordRequestParams => {
  return Axios.post(
    "/guest/guests/request_reset_password_link",
    resetPasswordRequestParams
  );
};

export const resetPassword = resetPasswordParams => {
  return Axios.put("/guest/guests/reset_password", resetPasswordParams);
};

export const resendConfirmationEmail = () => {
  return Axios.post("/guest/guests/send_new_confirmation_email");
};

export const resendNewConfirmationEmail = (email) => {
  return Axios.post("/guest/guests/resend_new_confirmation_email", email);
};
