import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import FullPageSpinner from "../FullPageSpinner";
import Post from "./post";
import { getCategory } from "../../api/blogs";
import { getPostsByCategory } from "../../api/blogs";

export default () => {
  const { slug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState(false);
  const [posts, setPosts] = useState({});

  useEffect(() => {
    getCategory(slug).then(({ data }) => {
      if (data[0]) {
        setCategory(data[0]);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    if (category) {
      getPostsByCategory(category.id).then(({data}) => {
        if (data) setPosts(data);
        setIsLoading(false);
      });
    }
  }, [category]);

  return isLoading ? (
    <FullPageSpinner />
  ) : (
    <div>
      { category ? (
        <main id="main" className="site-main" role="main">
          {posts.map((post, i) => (
            <Post key={i} post={post} isMain={true} />
          ))}
        </main>
      ) : (
        <main id="main" className="site-main" role="main">
          <header className="page-header alignwide">
            <h1 className="page-title">Nothing here</h1>
          </header>
        </main>
      )}
    </div>
  );
};
