import {
  SET_NEXT_UP,
  INCREMENT_CURRENT_TRACK_INDEX,
  DENCREMENT_CURRENT_TRACK_INDEX,
  CONCAT_NEXT_UP
} from "../actions";

export default (state = null, action) => {
  switch (action.type) {
    case SET_NEXT_UP:
      return action.next_up;
    case CONCAT_NEXT_UP:
      return [...state, ...action.next_up];
    case INCREMENT_CURRENT_TRACK_INDEX:
      return state && state.length && state.slice(1);
    case DENCREMENT_CURRENT_TRACK_INDEX:
      return state;
    default:
      return state;
  }
};
