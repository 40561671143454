import {
  INSERT_NEW_TRACK,
  REMOVE_NEW_TRACK,
  UPDATE_NEW_TRACK,
  SHIFT_NEW_TRACK,
  RESET_NEW_UPLOADED_TRACKS,
} from "../actions";

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
}

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INSERT_NEW_TRACK:
      return [...state, action.newTrack];
    case REMOVE_NEW_TRACK:
      return state.filter(
        new_track => new_track.s3_object_key !== action.newTrack.s3_object_key
      );
    case UPDATE_NEW_TRACK:
      return state.map(old_new_track =>
        action.updatedNewTrack.s3_object_key === old_new_track.s3_object_key
          ? action.updatedNewTrack
          : old_new_track
      );
    case SHIFT_NEW_TRACK:
      return array_move(state, action.from, action.to);
    case RESET_NEW_UPLOADED_TRACKS:
      return INITIAL_STATE;
    default:
      return state;
  }
};
