import React from "react";
import { Link } from "react-router-dom";

import IndependentArtists from "./IndependentArtists";
import LandingHeader from "./LandingHeader";
import LandingFooter from "./LandingFooter";
import PromotionBlock from "./PromotionBlock";

import logo from "./images/logo.svg";
import bannerImg from "./images/banner-img.jpg";
import whyWeHereImg from "./images/why-we-here-img.jpg";

import "./styles.scss";
import "./responsive.scss";
import BannerSlider from "./BannerSlider";
import CommonContentMain from "./CommonContentMain";
import MobileEpk from "./MobileEpk";
import WhyWeHereMain from "./WhyWeHereMain";
import FreeMusicPromotionMain from "./FreeMusicPromotionMain";

const NewOnboarding = () => {
  return (
    <div className="onboarding-wrapper">
      <LandingHeader />
      <main id="main">
        <BannerSlider />
        <CommonContentMain />
        <MobileEpk />
        <WhyWeHereMain />
        <IndependentArtists />
        <FreeMusicPromotionMain />
      </main>
      <LandingFooter />
    </div>
  );
};

export default NewOnboarding;
