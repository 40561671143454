import { TOGGLE_PLAYING, SET_PLAYING } from "../actions";

export default (state = false, action) => {
  switch (action.type) {
    case TOGGLE_PLAYING:
      return !state;
    case SET_PLAYING:
      return action.playing;
    default:
      return state;
  }
};
