import React, { Component } from "react";
import FullPageSpinner from "./components/FullPageSpinner";
import { confirmEmail } from "./api/guests";
import Modal from "./components/shared/Modal/";
import OnBoardingComponent from './components/Onboarding/NewOnboarding';

import { isIOS } from "react-device-detect";

export default class AppLoader extends Component {
  state = { isLoading: true, s: 5 };
  componentDidMount = async () => {
    try {
      let confirmation_token = this.props.match.params.confirmation_token;
      let {
        data: { branch_link },
      } = await confirmEmail({ confirmation_token });
      setTimeout(function () {
        window.location.href = branch_link;
      }, 25);
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };
  render() {
    return this.state.isLoading ? (
      <FullPageSpinner />
    ) : (
      <>
        <OnBoardingComponent />
        <Modal id="confirmation_failed_modal" modaltitle="Already Confirmed">
          <p>
            Oops! Your email is already confirmed. You can <a href="/login">click here to login.</a>
          </p>
        </Modal>
        </>
    );
  }
}
