import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { logout } from "../../../api/authentication";
import logo from "./images/logo.svg";
import { ReactComponent as TwitterX } from "../../../public/icons/twitterX.svg";
import { useMediaQuery } from "react-responsive";

const year = new Date().getFullYear();

const LandingFooter = (props) => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 991px)",
  });

  return (
    <footer id="footer" className="blackBg">
      <div className="colorBorders top">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="container">
        {!isMobileDevice && (
          <div className="footerLogo">
            <img src={logo} style={{ width: 100, transform: `translateX(4.4%)` }} alt="" />
          </div>
        )}
        <div className="socialIcons" style={{ paddingTop: isMobileDevice ? 40 : 5 }}>
          <ul>
            <li>
              <a href="https://www.instagram.com/weunifimusic" target="_blank" alt="UNIFI Music on Instagram" title="UNIFI Music on Instagram">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/@weUNIFImusic" target="_blank" alt="UNIFI Music on YouTube" title="UNIFI Music on YouTube">
                <FontAwesomeIcon icon={faYoutube} />
              </a>
            </li>
            <li>
              <a href="https://x.com/weUNIFImusic" target="_blank" alt="UNIFI Music on X" title="UNIFI Music on X">
                <TwitterX />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/weUNIFImusic/" target="_blank" alt="UNIFI Music on Facebook" title="UNIFI Music on Facebook">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
            </li>
          </ul>
        </div>
        <div className="footerLinks commonPT">
          <ul>
            {!props.current_user && (
              <>
                <li>
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  <Link to="/signup">Sign up</Link>
                </li>
              </>
            )}
            {props.current_user && (
              <>
                <li>
                  <Link to="/" onClick={logout}>
                    Logout
                  </Link>
                </li>
              </>
            )}
            <li>
              <Link to="/about">About Us</Link>
            </li>
            <li>
              <a href="mailto:help@unifimusic.ai">Contact us</a>
            </li>
            <li>
              <a href="/terms-of-use" target="_blank">
                Terms of use
              </a>
            </li>
            <li>
              <a href="/privacy-policy" target="_blank">
                Privacy policy
              </a>
            </li>
            <li>
              <a href="/blog">Blog</a>
            </li>
          </ul>
        </div>
        <div className="copyrightTxt">
          {isMobileDevice && (
            <div className="footerLogo" style={{ padding: 0 }}>
              <img src={logo} style={{ transform: `translateX(4.4%) scale(0.7)` }} alt="" />
            </div>
          )}
          <small>
            <span>©2019-{year} Unify Music Technologies, Inc. </span>
            <span>All rights reserved. </span>
            {isMobileDevice ? <br /> : <></>}
            <span>
              Need Assistance? Email our support team at <a href="mailto:help@unifimusic.ai">help@unifimusic.ai</a>
            </span>
          </small>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
