import React from "react";
import Marquee from "react-fast-marquee";

import artistImg1 from "./images/artist-img1.jpg";
import artistImg2 from "./images/artist-img2.jpg";
import artistImg3 from "./images/artist-img3.jpg";
import artistImg4 from "./images/artist-img4.jpg";
import artistImg5 from "./images/artist-img5.jpg";
import artistImg6 from "./images/artist-img6.jpg";
import artistImg7 from "./images/artist-img7.jpg";
import artistImg8 from "./images/artist-img8.jpg";

const slides = [
  {
    img: artistImg1,
    href: "/cuscino",
    alt: "CUSCINO (San Diego/Los Angeles) on UNIFI Music",
    title: "CUSCINO (San Diego/Los Angeles) on UNIFI Music",
  },
  {
    img: artistImg2,
    href: "/slyder",
    alt: "Slyder (Germany) on UNIFI Music",
    title: "Slyder (Germany) on UNIFI Music",
  },
  {
    img: artistImg3,
    href: "/tiffmarie",
    alt: "TiffMarie (Chicago) on UNIFI Music",
    title: "TiffMarie (Chicago) on UNIFI Music",
  },
  {
    img: artistImg4,
    href: "/nuutrino",
    alt: "Nuutrino (Los Angeles) on UNIFI Music",
    title: "Nuutrino (Los Angeles) on UNIFI Music",
  },
  {
    img: artistImg5,
    href: "/anatural",
    alt: "A-natural (New Jersey) on UNIFI Music",
    title: "A-natural (New Jersey) on UNIFI Music",
  },
  {
    img: artistImg6,
    href: "/djcosign",
    alt: "DJCosign (Virginia) on UNIFI Music",
    title: "DJCosign (Virginia) on UNIFI Music",
  },
  {
    img: artistImg7,
    href: "/doñéandcity",
    alt: "Doñé and City (NYC) on UNIFI Music",
    title: "Doñé and City (NYC) on UNIFI Music",
  },
  {
    img: artistImg8,
    href: "/lennyharold",
    alt: "Lenny Harold (New Jersey) on UNIFI Music",
    title: "Lenny Harold (New Jersey) on UNIFI Music",
  },
];

const Artist = ({ href, img, alt, title }) => {
  return (
    <div>
      <div className="artistsSlider-item">
        <a href={href}>
          <img src={img} alt={alt} title={title} />
        </a>
      </div>
    </div>
  );
};

const ArtistSlider = () => {
  return (
    <div className="artistsSliderOuter">
      <div className="artistsSliderInner">
        <div className="artistsSlider">
          <Marquee pauseOnHover speed={80} gradient={false}>
            {slides.map((slide, index) => (
              <Artist img={slide.img} href={slide.href} alt={slide.alt} title={slide.title} key={index} />
            ))}
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default ArtistSlider;
