import axios from "axios";

export const getPosts = () => {
  return axios.get("https://blog.unifimusic.ai/wp-json/wp/v2/posts/?_embed");
};

export const getPost = (slug) => {
  return axios.get(`https://blog.unifimusic.ai/wp-json/wp/v2/posts/?slug=${slug}&_embed`);
};

export const getCategory = (slug) => {
  return axios.get(`https://blog.unifimusic.ai/wp-json/wp/v2/categories/?slug=${slug}`);
};

export const getPostsByCategory = (id) => {
  return axios.get(`https://blog.unifimusic.ai/wp-json/wp/v2/posts/?categories=${id}&_embed`);
};
