import { SET_LOADING_MORE_NEXT_UP } from "../actions";

export default (state = true, action) => {
  switch (action.type) {
    case SET_LOADING_MORE_NEXT_UP:
      return action.loadingMoreNextUp;
    default:
      return state;
  }
};
