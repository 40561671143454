import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { useMediaQuery } from "react-responsive";

import fiercelyIndependentImg1 from "./images/fiercely-independent-img1.jpg";
import fiercelyIndependentImg2 from "./images/fiercely-independent-img2.jpg";
import fiercelyIndependentImg3 from "./images/fiercely-independent-img3.jpg";
import fiercelyIndependentImg4 from "./images/fiercely-independent-img4.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const artists = [
  {
    img: fiercelyIndependentImg1,
    name: "Artists",
    alt: "UNIFI Music is Free Music Promotion for ARTISTS, and connection with a growing global community of independent music makers to CONNECT, COLLAB, and CREATE",
  },
  {
    img: fiercelyIndependentImg2,
    name: "Podcasters",
    alt: "UNIFI Music is Free Music Promotion for PODCASTERS, and connection with a growing global community of independent music makers",
  },
  {
    img: fiercelyIndependentImg3,
    name: "fans",
    alt: "UNIFI Music enables FANS to find new independent music to fall in love with, and makes connecting directly with the independent artists and musicians who create it easier than ever.",
  },
  {
    img: fiercelyIndependentImg4,
    name: "Record labels",
    alt: "UNIFI Music is Free Music Promotion for INDEPENDENT RECORD LABELS, and connection with a growing global community of independent music makers",
  },
];

const CustomSlide = ({ index, img, alt, name, ...props }) => {
  return (
    <div {...props}>
      <div key={index}>
        <div className="fiercelyIndependentGrid">
          <div className="borderColors opacity">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Link to="/">
            <img src={img} alt={alt} />
          </Link>
          <div className="rotateTitle">
            <h4>{name}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndependentArtists = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    cssEase: "linear",
  };

  return (
    <div className="fiercelyIndependentMain blackBg smallPT">
      <div className="container">
        <div className="row">
          <div className="who-we-are-div">
            <h3 className="mdTitle">Who We're Here For</h3>
          </div>
        </div>
      </div>
      {isMobileDevice ? (
        <div className="fiercelyIndependentInner scrollToDo myGallery">
          <div className="item wow fadeIn" data-wow-delay="00ms" data-wow-duration="800ms">
            <div className="fiercelyIndependentGrid">
              <div className="borderColors opacity">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="item-image">
                <Link to="/">
                  <img
                    src={fiercelyIndependentImg1}
                    alt="UNIFI Music is Free Music Promotion for ARTISTS, and connection with a growing global community of independent music makers to CONNECT, COLLAB, and CREATE"
                  />
                </Link>
              </div>
              <div className="rotateTitle">
                <h4>Artists</h4>
              </div>
            </div>
          </div>
          <div className="item wow fadeIn" data-wow-delay="150ms" data-wow-duration="800ms">
            <div className="fiercelyIndependentGrid">
              <div className="borderColors opacity">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="item-image">
                <Link to="/">
                  <img
                    src={fiercelyIndependentImg2}
                    alt="UNIFI Music is Free Music Promotion for PODCASTERS, and connection with a growing global community of independent music makers"
                  />
                </Link>
              </div>
              <div className="rotateTitle">
                <h4>Podcasters</h4>
              </div>
            </div>
          </div>
          <div className="item wow fadeIn" data-wow-delay="300ms" data-wow-duration="800ms">
            <div className="fiercelyIndependentGrid">
              <div className="borderColors opacity">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="item-image">
                <Link to="/">
                  <img
                    src={fiercelyIndependentImg3}
                    alt="UNIFI Music enables FANS to find new independent music to fall in love with, and makes connecting directly with the independent artists and musicians who create it easier than ever."
                  />
                </Link>
              </div>
              <div className="rotateTitle">
                <h4>fans</h4>
              </div>
            </div>
          </div>
          <div className="item wow fadeIn" data-wow-delay="450ms" data-wow-duration="800ms">
            <div className="fiercelyIndependentGrid">
              <div className="borderColors opacity">
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div className="item-image">
                <Link to="/">
                  <img
                    src={fiercelyIndependentImg4}
                    alt="UNIFI Music is Free Music Promotion for INDEPENDENT RECORD LABELS, and connection with a growing global community of independent music makers"
                  />
                </Link>
              </div>
              <div className="rotateTitle">
                <h4>Record labels</h4>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="fiercelyIndependentInner scrollToDo myGallery">
          {artists.map((item, index) => (
            <CustomSlide img={item.img} alt={item.alt} name={item.name} key={index} />
          ))}
        </div>
      )}
    </div>
  );
};

export default IndependentArtists;
