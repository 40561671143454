import {
  SET_QUEUE,
  INCREMENT_CURRENT_TRACK_INDEX,
  DENCREMENT_CURRENT_TRACK_INDEX
} from "../actions";

export default (state = null, action) => {
  switch (action.type) {
    case SET_QUEUE:
      return action.queue;
    case INCREMENT_CURRENT_TRACK_INDEX:
      let ret = {
        ...state,
        current_track_index: state.current_track_index + 1
      };
      return ret;
    case DENCREMENT_CURRENT_TRACK_INDEX:
      return {
        ...state,
        current_track_index: state.current_track_index - 1
      };
    default:
      return state;
  }
};
