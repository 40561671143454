import React from "react";
import "./styles.scss";
import { Link } from "react-router-dom";
import { ReactComponent as X } from "../../../public/x.svg";

export default (props) => {
  return (
    <div className="hv-modal" {...props}>
      <div id="hv-modal-dialog" className="hv-modal-dialog">
        <div className="text-right">
          {!props.onCancel ? (
            <Link id="x" to="/">
              <X className="x m-4" />
            </Link>
          ) : (
            <a id="x" onClick={props.onCancel}>
              <X className="x m-4" />
            </a>
          )}
        </div>
        <h1 className="hv-modal-title">{props.modaltitle}</h1>
        <div className="hv-modal-body mt-4" id={props.id}>
          {props.children}
        </div>
      </div>
    </div>
  );
};
