import {
  SET_CURRENTLY_PLAYING,
  SET_DURATION,
  MARK_READY,
  TOGGLE_CURRENTLY_PLAYING_LIKE_STATE
} from "../actions";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_CURRENTLY_PLAYING:
      return action.currently_playing;
    case SET_DURATION:
      return { ...state, duration: action.duration };
    case MARK_READY:
      return { ...state, status: "ready" };
    case TOGGLE_CURRENTLY_PLAYING_LIKE_STATE:
      return {
        ...state,
        is_liked: !state.is_liked,
        likes_count: (!state.is_liked)
          ? state.likes_count + 1
          : state.likes_count - 1
      };
    default:
      return state;
  }
};
