export const assets = {
  images: {
    onboarding: {
      desktop: {
        bg1: require("./images/onboarding/desktop/bg1.png"),
        bg2: require("./images/onboarding/desktop/bg2.jpeg"),
        bg3: require("./images/onboarding/desktop/bg3.jpeg"),
        bg4: require("./images/onboarding/desktop/bg4.jpeg"),
        bg5: require("./images/onboarding/desktop/bg5.jpeg"),
        bg6: require("./images/onboarding/desktop/bg6.jpeg"),
        bg7: require("./images/onboarding/desktop/bg7.jpeg"),
        bg8: require("./images/onboarding/desktop/bg8.jpeg"),
        bg9: require("./images/onboarding/desktop/bg9.jpeg"),
        bg10: require("./images/onboarding/desktop/bg10.jpeg"),
        bg11: require("./images/onboarding/desktop/bg11.jpeg"),
        bg12: require("./images/onboarding/desktop/bg12.jpeg"),
        bg13: require("./images/onboarding/desktop/bg13.jpeg"),
        bg14: require("./images/onboarding/desktop/bg14.jpeg"),
        bg15: require("./images/onboarding/desktop/bg15.jpeg"),
        bg16: require("./images/onboarding/desktop/bg16.jpeg"),
      },
      mobile: {
        bg1: require("./images/onboarding/mobile/bg1.jpeg"),
        bg2: require("./images/onboarding/mobile/bg2.jpeg"),
        bg3: require("./images/onboarding/mobile/bg3.jpeg"),
        bg4: require("./images/onboarding/mobile/bg4.jpeg"),
        bg5: require("./images/onboarding/mobile/bg5.jpeg"),
        bg6: require("./images/onboarding/mobile/bg6.jpeg"),
        bg7: require("./images/onboarding/mobile/bg7.jpeg"),
        bg8: require("./images/onboarding/mobile/bg8.jpeg"),
        bg9: require("./images/onboarding/mobile/bg9.jpeg"),
        bg10: require("./images/onboarding/mobile/bg10.jpeg"),
      },
      landing: {
        bg1: require("./images/onboarding/desktop/bg1.png"),
        bg2: require("./images/onboarding/desktop/bg2.jpeg"),
        bg3: require("./images/onboarding/desktop/bg3.jpeg"),
        bg4: require("./images/onboarding/desktop/bg4.jpeg"),
        bg6: require("./images/onboarding/desktop/bg6.jpeg"),
        bg7: require("./images/onboarding/desktop/bg7.jpeg"),
        bg8: require("./images/onboarding/desktop/bg8.jpeg"),
        bg9: require("./images/onboarding/desktop/bg9.jpeg"),
        bg10: require("./images/onboarding/desktop/bg10.jpeg"),
        bg11: require("./images/onboarding/desktop/bg11.jpeg"),
        bg12: require("./images/onboarding/desktop/bg12.jpeg"),
      },
    },
  },
};
