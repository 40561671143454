import { SET_PROGRESS, SET_CURRENTLY_PLAYING } from "../actions";

export default (state = null, action) => {
  switch (action.type) {
    case SET_CURRENTLY_PLAYING:
      return null;
    case SET_PROGRESS:
      return action.progress;
    default:
      return state;
  }
};
