import React from "react";
import { Link } from "react-router-dom";
import whyWeHereImg from "./images/why-we-here-img.jpg";
import logo from "./images/logo.svg";

const WhyWeAreMain = () => {
  return (
    <div className="whyWeHereMain bgCover commonPY" style={{ backgroundImage: `url(${whyWeHereImg})` }}>
      <div className="colorBorders top">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className="whyWeHereInner">
        <div className="container">
          <div className="row">
            <div className="col-md-50 whyWeHereContent">
              <h3>Why we’re here.</h3>
              <p>
                UNIFI MUSIC exists to support and amplify you, the next wave of visionary artists: You, the producers and songwriters and MCs, the
                instrumentalists and vocalists and dancers that come together to create the music we all love.
              </p>
              <p>
                UNIFI MUSIC is here to give you, the fiercely independent artist, a place to showcase your unique talents easily, and free of heavy
                monthly fees for things you don’t need. We’re here to help you not have to string together six different platforms just to get your
                creations seen and heard.
              </p>
              <p>
                And fear not, fans. We’re here for you, too. You, the supporters of great talent and artistry, we’ve created a place for you to easily
                discover the freshest new artists out there, whether you’re on the hunt for your next favorite summer jam, or just need a quick
                Netflix-and-chill soundtrack.
              </p>
              <p>After all, nothing brings people together like music. And when people come together, the world is a better place.</p>
              <p>Welcome to the new world.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="siteLogo position">
        <Link to="/">
          <img src={logo} alt="" />
        </Link>
      </div>
      <div className="colorBorders bottom d-sm-none">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

export default WhyWeAreMain;
