import React, { useState, useEffect } from "react";
import FullPageSpinner from "../FullPageSpinner";
import Post from "./post";
import { getPosts } from "../../api/blogs";

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    getPosts().then(({ data }) => {
      setPosts(data);
      setIsLoading(false);
    });
  }, []);

  return isLoading ? (
    <FullPageSpinner />
  ) : (
    <main id="main" className="site-main" role="main">
      {posts.map((post, i) => (
        <Post key={i} post={post} isMain={true} />
      ))}
    </main>
  );
};
